/* UserHashtag.module.css */

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 1000;
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-height: 80%;
    overflow-y: auto;
    width: 85%;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
}

.modalHeader {
    box-shadow: 0 14px 8px rgba(0, 0, 0, 0.1);
    padding: 0 10px 10px;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 0;
}

.genreList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1;
    overflow-y: auto;
}

.genreItem {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    background-color: #f9f9f9;
    color: #505050;
    transition: background-color 0.3s, border-color 0.3s, transform 0.2s ease-in-out;
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5;
    font-size: 1.4rem;
    white-space: nowrap;
    min-height: 2.5rem;
    max-height: 5rem;
}

.genreItem:hover {
    transform: scale(105%);
}

.button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(40% - 40px);
}

.button:hover {
    background-color: #0056b3;
}
