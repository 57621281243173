.header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #4285f4;
    text-decoration: none;
}
.nav {
    display: flex;
    gap: 1rem;
}
.nav-link {
    color: #333;
    text-decoration: none;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}
.nav-link:hover, .nav-link.active {
    background-color: #e8f0fe;
    color: #4285f4;
}
.auth-buttons {
    display: flex;
    gap: 1rem;
}
.auth-button {
    text-decoration: none;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s;
}
.login-button {
    background-color: #e8f0fe;
    color: #4285f4;
}
.login-button:hover {
    background-color: #d2e3fc;
}
.signup-button {
    background-color: #4285f4;
    color: #fff;
}
.signup-button:hover {
    background-color: #3b78e7;
}
