.comment-section {
    margin-top: 1rem;
    padding: .5rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment {
    min-height: 1.5rem;
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 0.3rem 0 0 0.3rem;
    margin-bottom: 0.5rem;
    border-left: 3px solid rgba(66, 133, 244, 0.5);
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px rgb(213, 213, 213);
    position: relative;
}

.comment.current-user {
    border-left: 3px solid rgb(224, 6, 6);
}

.comment * {
    margin-left: .4rem;
}

.comment-meta {
    font-size: 0.7rem;
    color: #888;
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
}

.comment-username {
    margin: 0;
    padding: .2rem;
    font-weight: bold;
    border-bottom: 4px solid #4285f4;
    border-left: 4px solid #4285f4;
    border-bottom-left-radius: 5px;
}

.comment.current-user >.comment-username {
    border-bottom: 4px solid #e00606;
    border-left: 4px solid #e00606;
}

.comment-content {
    margin-bottom: 0.9rem;
    font-size: 0.9rem;
}

.reply {
    margin-left: 2rem;
    background-color: #f1f1f1;
    border-radius: 4px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border-left: 4px solid #888;
    display: flex;
    flex-direction: column;
}

.reply-form {
    margin-top: .5rem;
    margin-left: 1.3rem;
    margin-right: .3rem;
    background-color: #e9e9e9;
    padding: 1rem;
    border-radius: 4px;
    position: relative;
}

.reply-textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    min-height: 100px;
}

.comment-form {
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.comment-form h3 {
    margin: 0 0 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.comment-form textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    min-height: 100px;
}

.comment-form .button {
    margin-top: 1rem;
}

.comments-list {
    margin-bottom: 2rem;
}

.comment-item {
    padding: 0.5rem 0;
    border-bottom: 1px solid #ddd;
}

.comment-item:last-child {
    border-bottom: none;
}

.comment-form-container {
    margin-top: 2rem;
}

.comment-form-container h2 {
    margin-bottom: 1rem;
}

.comment-form-container form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.comment-form-container textarea {
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
}

.comment-form-container .button {
    align-self: flex-end;
}

.replies {
    margin: 0 0 0 .65rem;
    border-left: 2px solid #ccc;
    padding-left: .8rem;
}

.buttons {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    align-self: flex-end;
    padding: 0.3rem 0.6rem;
}

.edit-button,
.delete-button,
.reply-button {
    background-color: #4285f4;
    color: #fff;
    border: none;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    border-radius: 4px;
    cursor: pointer;
}

.edit-button:hover,
.delete-button:hover,
.reply-button:hover {
    background-color: #3b78e7;
}
.update_comment{
    margin-right: 1rem;
    margin-top: 1rem;
}

.update_comment > .left_button{
    position: absolute;
    right: .62rem;
    bottom: 3rem;
}

.left_button{
    position: absolute;
    right: 1rem;
    bottom: 1.38rem;
}

.reply-form .left_button{
    position: absolute;
    right: .65rem;
    bottom: 1.45rem;
}

.page-button{
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #333;
    text-decoration: none;
}

.page-button:hover{
    background-color: #4285f4;
    color: #fff;
    border-color: #4285f4;
}

.page-button.active{
    background-color: #4285f4;
    color: #fff;
    border-color: #4285f4;
}
