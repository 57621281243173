.NotFound{
    margin-top: 3rem;
    font-size: 10rem;
    text-align: center;
    height: 100%;
    background-color: #4285f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;

}