.body, html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #f0f2f5;
    color: #333;
    line-height: 1.6;
}

.container {
    max-width: 1400px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.button{
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}

.button:hover {
    background-color: #3b78e7;
}

.postDetail {
    display: flex;
    gap: 1rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 2rem;
    margin-bottom: 2rem;
}

.leftColumn {
    flex: 1;
    max-width: 420px;
}

.rightColumn {
    flex: 2;
    min-height: 250px;
}

.bookCover {
    width: 100%;
    max-width: 150px;
    height: auto;
    border-radius: 8px;
}

.bookInfo {
    margin-top: 1rem;
}

.bookInfo h2 {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.bookInfo p {
    margin: 0.5rem 0;
}

.postDetailTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.postDetailMeta {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 1rem;
}

.contentDetailTitle{
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: .5rem;
    margin-top: .5rem;
}

.contentDetailMeta{
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 1rem;
}

.postDetailContent {
    line-height: 1.8;
    margin-bottom: 2rem;
}

.topBox{
    min-height: 250px;
    max-height: 600px;
    overflow: auto;
}

.post_img {
    border-radius: 1.2rem ;
    width: 280px;
    height: 400px;
    object-fit: cover;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: right;
    position: relative;
    margin-bottom: 1.2rem;
    background-position: center;
    background-size: cover;
}
.content_img {
    border-radius: 1.2rem ;
    width: 400px;
    height: 580px;
    object-fit: cover;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: right;
    position: relative;
    margin-bottom: 1.2rem;
    background-position: center;
    background-size: cover;
}

.post_platform{
    border-radius: 1.2rem ;
    background-color: #007bff;
    position: absolute;
    color:white;
    font-weight: bolder;
    width: 100%;
    text-align: center;
    bottom: -1rem;
    box-shadow: 0 .2rem 5px rgba(0, 0, 0, 0.5);
}

.review_container{
    max-width: 1400px;
    min-height: 150px;
    margin: 2rem auto 1.8rem;
    padding: 0 1rem;
}

.detail_button{
    display: inline-block;
    padding: 0.5rem 1.5rem;
    background-color: #4285f4;
    margin-right: .5rem;
    margin-left: .5rem;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}

.detail_button:hover{
    background-color: #0f3d89;
    color: #ffefef;
}

.button_container {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    position: relative;
    bottom: -107%;
    left: -5px;
}

.content_detail_buttons{
    display: flex;
    position: relative;
    bottom: -107%;
    left: -5px;
    gap: 1rem;
}

.content_detail_button {
    display: flex;
    padding: 0.4rem 1rem;
    background-color: #4285f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
    flex-direction: row;
    z-index: 99;
}

.content_detail_button > a {
    text-decoration: none;
}

.content_detail_button:hover {
    background-color: #0f3d89;
    color: #ffefef;
}

.tag_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}

.tag_button {
    padding: 0.08rem .4rem;
    background-color: #f0f2f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tag_button:hover {
    background-color: #4ea9f3;
}

.tag_container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 1rem;
}

.tag_button {
    padding: 0.1rem .8rem;
    background-color: #f0f2f5;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tag_button:hover {
    background-color: #4ea9f3;
}

