.container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
}
.button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}
.button:hover {
    background-color: #3b78e7;
}
.button + .button {
    margin-left: 1rem;
}
.community-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}
.board-list {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.board-item {
    padding: 1rem;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
.board-item *{
    display: inline-block;
}
.board-item:hover{
    background-color: #dedede;
}
.board-item:last-child {
    border-bottom: none;
}
.board-title {
    font-size: 1.1rem;
    font-weight: 500;
    color: #333;
    text-decoration: none;
}
.board-title:hover {
    color: #4285f4;
}
.board-meta {
    font-size: 0.9rem;
    color: #888;
}
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.page-link {
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #333;
    text-decoration: none;
}
.page-link:hover, .page-link.active {
    background-color: #4285f4;
    color: #fff;
    border-color: #4285f4;
}
