
.container {
    padding: 2rem;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:hover {
    background-color: #ddd;
}

.statusSelect {
    width: 100%;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    cursor: pointer;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.pagination button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
