ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}


*, *::before, *::after {
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #f0f2f5;
    color: #333;
    line-height: 1.6;
}
.container {
    max-width: 1400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.profileHeader {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}
.profileInfo h1 {
    margin: 0 0 0.5rem;
}
.profileInfo p {
    margin: 0;
    color: #666;
}
.button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    border: 2px solid white;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
}

.deleteButton{
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    border: 2px solid white;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
}
.button:hover {
    background-color: #3b78e7;
}
.section {
    margin-bottom: 2rem;
}
.section h2 {
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
}
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
    gap: 1rem;
}
.card {
    width:250px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
}
.card h3 {
    margin: 0 0 0.5rem;
    font-size: 1rem;
}
.card p {
    margin: 0;
    color: #666;
    font-size: 0.9rem;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}
.name{

}

.my_page_link {
    list-style-type: none;
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #333;
    text-decoration: none;
}
.my_page_link:hover, .my_page_link.active {
    background-color: #4285f4;
    color: #fff;
    border-color: #4285f4;
}

.more_btu {
    display: inline-block;
    color: #6d6d6d;
    font-size: 0.9rem;
}

.more_btu:hover {
    color: #2d2d2d;
}

.sectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editProfile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.editInput {
    margin-bottom: 10px;
    padding: 8px;
    font-size: 1rem;
    width: 100%;
}
.cardGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    justify-content: space-between;
}

.cardGrid .card {
    width: 100%;
    height: 300px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 1rem;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.hashtagGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 16px;
    margin-top: 20px;
}


.hashtagBlock {
    background-color: #f5f5f5;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tag {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.details {
    margin-top: 8px;
    font-size: 14px;
    color: #777;
}

.tier,
.count {
    display: block;
}

.hashtagBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 8px;
    margin: 4px 0;
    border-radius: 4px;
}

.deleteButton {
    background-color: red;
    color: white;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
}

.deleteButton:hover {
    background-color: darkred;
}
.hashtagBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5;
    padding: 8px;
    margin: 4px 0;
    border-radius: 4px;
    cursor: pointer;
}

.selected {
    background-color: #d1e7fd; /* 선택된 해시태그의 배경색 */
}

.checkbox {
    margin-left: 8px;
}

.editButton {
    margin-left: 16px;
    padding: 4px 8px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 4px;
}

.deleteSelectedButton {
    margin-top: 16px;
    padding: 8px 16px;
    border: none;
    background-color: red;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    display: block;
    width: 100%;
}
