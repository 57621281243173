
.container {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.form-group {
    margin-bottom: 1.5rem;
}
label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
}
input[type="text"],
textarea,
select {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
}
textarea {
    min-height: 200px;
    resize: vertical;
}
.button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}
.button:hover {
    background-color: #3b78e7;
}
.button-secondary {
    background-color: #f1f3f4;
    color: #333;
}
.button-secondary:hover {
    background-color: #e8eaed;
}

.selected-tag-container {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.selected-tag {
    display: inline-block;
    margin: 0.2rem;
    padding: 0.2rem 0.5rem;
    background-color: #007BFF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.selected-tag:hover {
    background-color: #0056b3;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.tag {
    margin: 0.2rem;
    padding: 0.2rem 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f1f1f1;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tag:hover {
    background-color: #ddd;
}

.tag-selected {
    background-color: #007BFF;
    color: white;
    border: 1px solid #0056b3;
}
