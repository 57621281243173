.container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
    padding: 2rem;
    background-color: #f5f5f5;
}

.details {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;
}

.actions {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.updateBtn, .deleteBtn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.updateBtn {
    background-color: #28a745;
    color: white;
}

.updateBtn:hover {
    background-color: #218838;
}

.deleteBtn {
    background-color: #dc3545;
    color: white;
}

.deleteBtn:hover {
    background-color: #c82333;
}

.details p {
    margin: 0.5rem 0;
    font-size: 1rem;
}

.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    font-size: 0.9rem;
}
