

.container {
    max-width: 1400px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* 프로필 헤더 스타일 */
.profileHeader {
    margin-bottom: 2rem;
}

.profileHeader h1 {
    margin: 0;
    font-size: 2rem;
    color: #333;
}

.profileHeader p {
    margin: 0.5rem 0;
    color: #666;
}

/* 섹션 스타일 */
.section {
    margin-bottom: 2rem;
}

.section h2 {
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.75rem;
    color: #333;
}
/* 공통 버튼 스타일 */
.statusButton, .roleButton {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: none;
    color: #fff;
    font-weight: bold;
    cursor: default;
    text-align: center;
    margin-right: 0.5rem;
}

/* 상태에 따른 버튼 색상 */
.statusNormal {
    background-color: green;
}

.statusDeleted {
    background-color: red;
}

.statusBlocked {
    background-color: orange;
}

/* 권한에 따른 버튼 색상 */
.roleUser {
    background-color: blue;
}

.roleAdmin {
    background-color: purple;
}

