
.c_container {
    max-width: 1200px;
    min-height: 1200px;
    margin: 2rem auto 4rem;
    padding: 0 1rem;
}

.button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}

.button:hover {
    background-color: #3b78e7;
}

.button + .button {
    margin-left: 1rem;
}

.communityHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.boardList {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom:10rem ;
}

.boardItem {
    border: 2px solid rgba(80, 80, 80, 0.15);
    background-color: #fff;
    padding: 0 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.postT{
    margin-top: 0;
    font-weight: bolder;
    transform: scale(104%) translateX(-4px);
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(49, 49, 49, 0.35);
}

.Posts{
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    padding-bottom: 0;
}

.boardTitle {
    font-size: 2rem;
    font-weight: bolder;
    color: #007bff;
    text-decoration: none;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.boardTitle:hover {
    background-color: #dbdce0;
}

.boardPosts {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.boardPosts {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* 게시글 사이에 1rem 간격 추가 */
}

.board-list{
    min-height: 30px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 2px black;
}

.boardPost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
}

.boardPost:last-child {
    border-bottom: none;
}


.boardPost:hover {
    background-color: #e5e3e3;
}

.postTitle {
    margin-left: 20px;
    font-size: 1.1rem;
    color: #343434;
    text-decoration: none;
}

.postTitle:hover {
    text-decoration: underline;
}

.postMeta {
    font-size: 0.7rem;
    color: #666;
}

.postMeta span {
    margin-right: 1rem;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.pageLink {
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.pageLink:hover, .pageLink.active {
    background-color: #4285f4;
    color: #fff;
    border-color: #4285f4;
}

.post-actions {
    margin-top: 20px;
}

.post-edit-button, .post-delete-button {
    display: inline-block;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.post-edit-button:hover {
    background-color: #0056b3;
}

.post-delete-button {
    background-color: #dc3545;
}

.post-delete-button:hover {
    background-color: #c82333;
}