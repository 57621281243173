.container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
}

.main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
}

.crawling-form {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 2rem;
    position: relative;
    text-align: center;
    min-width: 400px;
    min-height: 700px;
    margin: 20px;
}

.login-form {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 2rem;
    position: relative;
    text-align: center;
    min-height: 700px;
    margin: 20px;
}
.sort_btn{
    background:none;
    border: none;
    font-size: 1.2rem;
    font-weight: bolder;
}
.tags-form {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 2rem;
    position: relative;
    text-align: center;
    min-height: 700px;
    margin: 20px;
}

.social-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
}

.social-btn {
    display: inline-block;
    width: 80%;
    padding: 0.6rem 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: #fff;
    transition: opacity 0.3s, transform 0.2s;
}

.social-btn:hover {
    opacity: 0.9;
    transform: translateY(-2px);
}


.signup-link {
    margin-top: 1.5rem;
    font-size: 0.9rem;
}

.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    font-size: 0.9rem;
}

.input-field {
    width: 100%;
    padding: 0.6rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
}

.login-btn:hover {
    opacity: 0.9;
    transform: translateY(-2px);
}

.crawling-btn-container, .management-btn-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.crawling-btn, .management-btn {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.crawling-btn:hover, .management-btn:hover {
    background-color: #0056b3;
}

.K_crawling{
    background-color: #fae100;

}

.K_crawling:hover{
    background-color: #796e00;

}


.M_crawling{
    background-color: #0054a8;

}

.M_crawling:hover{
    background-color: #002245;

}

.R_crawling{
    background-color: #4b91db;

}

.R_crawling:hover{
    background-color: #234263;

}
/* 추가된 모달 스타일 */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
