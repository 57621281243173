
.container {
    padding: 2rem;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    cursor: pointer;
    word-wrap: break-word;
}

.table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.table th:nth-child(1),
.table td:nth-child(1) {
    width: 1%;
}

.table th:nth-child(2),
.table td:nth-child(2) {
    width: 4%;
}

.table th:nth-child(3),
.table td:nth-child(3) {
    width: 6%;
}

.table th:nth-child(4),
.table td:nth-child(4) {
    width: 12%;
}



.table th:nth-child(6),
.table td:nth-child(6) {
    width: 3%;
}

.table th:nth-child(7),
.table td:nth-child(7) {
    width: 5%;
}

.table th:nth-child(8),
.table td:nth-child(8) {
    width: 6%;
}

.table th:nth-child(9),
.table td:nth-child(9) {
    width: 10%;
}

.table th:nth-child(10),
.table td:nth-child(10) {
    width: 5%;
}

.table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tr:hover {
    background-color: #ddd;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.pagination button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
}

.pagination button:hover {
    background-color: #0056b3;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.deleteButton {
    padding: 0.3rem 0.6rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #ff4d4d;
    color: white;
    transition: background-color 0.3s;
}

.postType-CONTENT {
    background-color: #d0d07a;
}

.postType-REVIEW {
    background-color: #f35b5b;

}

.postType-NOMAL {
    background-color: #3088de;

}

.backButton {
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s;
}

.backButton:hover {
    background-color: #0056b3;
}

.container {
    padding: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    white-space: nowrap;

}
.bodyCell{
    max-width: 400px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.selectedHeader {
    color: #ff3535;
}

.postType-ANNOUNCEMENT {
    background-color: #f9f9f9;
}

.postType-QUESTION {
    background-color: #666666;
}

.deleteButton {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.deleteButton:hover {
    background-color: darkred;
}

.pagination button {
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
