body, html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #f0f2f5;
    color: #333;
    height: 100%;
}
.container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
}
.main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.8rem;
}
.login-form {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 2rem;
    width: 100%;
    max-width: 360px;
    text-align: center;
}
.social-btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
}
.social-btn {
    display: inline-block;
    width: 80%;
    padding: 0.6rem 1rem;
    margin-bottom: 1rem;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: #fff;
    transition: opacity 0.3s, transform 0.2s;
}
.social-btn:hover {
    opacity: 0.9;
    transform: translateY(-2px);
}
.naver-btn {
    background-color: #03C75A;
}
.kakao-btn {
    background-color: #FEE500;
    color: #000;
}
.signup-link {
    margin-top: 1.5rem;
    font-size: 0.9rem;
}
.footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 1rem;
    font-size: 0.9rem;
}
