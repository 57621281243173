.open-modal-button {
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.open-modal-button:hover {
    background-color: #3b78e7;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-width: 1200px;
    height: 600px;
    width: 100%;
}

.close-modal-button {
    position: absolute;
    top: .5rem;
    right: .5rem;
    background: #4285f4;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    color: #ffffff;
    cursor: pointer;
    padding: 3px;
}

.close-modal-button:hover {
    background: #3872d0;
}

.search-container {
    margin-top: 1rem;
}

.search-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.search-results {
    margin-top: 1rem;
    max-height: 450px;
    overflow-y: auto;
    padding: 0.5rem;
    background-color: #f9f9f9;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.result-item {
    background-color: #ffffff;
    padding: 1rem;
    margin-bottom: 0.75rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #4285f4;
}

.result-item h3 {
    margin: 0 0 0.5rem;
    font-size: 1.2rem;
    color: #333;
}

.result-item p {
    margin: 0;
    color: #666;
}

.tag-button {
    display: block;
    width: 100%;
    padding: 0.5rem;
    background-color: #f0f2f5;
    border: none;
    text-align: left;
    cursor: pointer;
    margin-top: 0.5rem;
    border-radius: 4px;
}

.tag-button:hover {
    background-color: #e8eaed;
}
.search-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
}

.search-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
    width: 100%;
    box-sizing: border-box;
}

.search-button {
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    color: #fff;
    white-space: nowrap;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-button:hover {
    background-color: #3b78e7;
}
