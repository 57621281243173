.container {
    max-width: 1600px;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.card {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    min-height: 200px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: calc(20% - 1rem);
}

.card:hover {
    transform: translateY(-5px);
}

.card-title {
    margin: 0;
    font-size: 1.25rem;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.card-platform{
    font-size: 0.8rem;
    padding: 5px 10px;
    border-radius: 1.2rem ;
    background-color: #007bff;
    position: absolute;
    color:white;
    font-weight: bolder;
    text-align: center;
    transform: translateY(-24%) translateX(2%);
    box-shadow: 0 .2rem 5px rgba(0, 0, 0, 0.5);
}
.card-img {
    border-radius: 8px;
    width: 100%;
    height: 24.5rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: right;
}


.card-description {
    margin: 0.5rem 0 0;
    color: #666;
    font-size: 0.9rem;
    max-height: 3.6em;
    line-height: 1.8em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
}

.card-author{
    color: #797979;
    margin: 0 0 0 0.2rem;
}

.card-genre {
    margin-top: 0.5rem;
    margin-bottom:0 ;
    font-size: 0.8rem;
    color: #4285f4;
}

.card-rating {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: #f39c12;
}

/* 동일한 높이를 맞추기 위한 설정 */
.container {
    align-items: stretch;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
