.body, html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: #f0f2f5;
    color: #333;
    line-height: 1.6;
}

.container {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.button {
    display: inline-block;
    padding: 0.5rem 1rem;
    background-color: #4285f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}

.post.button * {
    margin: 0;
}

.button:hover {
    background-color: #3b78e7;
}

.post-detail {
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 1rem;
}

.post-detail-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.post-detail-meta {
    font-size: 0.7rem;
    color: #888;
    margin-bottom: 1rem;
}

.post-detail-content {
    line-height: 1.8;
    margin-bottom: 2rem;
}

.community-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}
.container.review_head{
    margin:0;
}
.community-header.review_head{
    margin:0;
}
.review_head{
    margin:0;
}

.board-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.board-item {
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.board-item:hover {
    background-color: #f0f0f0;
}

.board-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    text-decoration: none;
}

.board-meta {
    font-size: 0.9rem;
    color: #666;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.page-link {
    padding: 0.5rem 1rem;
    margin: 0 0.25rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #333;
    text-decoration: none;
    cursor: pointer;
}

.page-link:hover, .page-link.active {
    background-color: #4285f4;
    color: #fff;
    border-color: #4285f4;
}

.post-like-button {
    position: relative;
    top: 20px;
    left: 77%;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.post-like-button.liked {
    background-color: #dc3545;
}

.post-like-button:hover {
    background-color: #0056b3;
}

.post-like-button.liked:hover {
    background-color: #c82333;
}

.post-actions {
    position: relative;
    top: .9rem;
    right: 1rem;
    display: flex;
    gap: 10px;
}

.post-edit-button, .post-delete-button, .post-save-button {
    padding: 0.7rem 1rem;
    background-color: #4285f4;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.post-edit-button:hover, .post-save-button:hover {
    background-color: #3b78e7;
}

.post-delete-button:hover {
    background-color: #d9534f;
}

.post-detail-container {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    max-width: 1400px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.post-image-box {
    flex: 1;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.post-image-box img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.post-detail {
    flex: 2;
    padding: 0.5rem 1rem 1rem;
    position: relative;
}
.post-detail_2 {
    padding: 0.5rem 1rem 1rem;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.post-detail_3 {
    padding: 1rem;
    position: relative;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.related-posts{
    margin-bottom: 2rem;
}


