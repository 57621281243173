.topContentContainer {
    max-width: 1600px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.topGenreFilter {
    display: flex;
    gap: 10px;
    overflow-x: auto;
}

.topSubgenreFilter {
    display: flex;
    gap: 10px;
    overflow-x: auto;
}

.topGenreButton, .topSubgenreButton, .topRankingTab {
    padding: 10px 20px;
    cursor: pointer;
}

.topGenreButton.active, .topSubgenreButton.active, .topRankingTab.active {
    background-color: #000;
    color: #fff;
}

:root {
    --swiper-navigation-size: 60px;
}

.cardContainer {
    position: relative;
}

.rank {
    position: absolute;
    left: 1.5rem;
    top: 3.5rem;
    background-color: rgba(255, 245, 0, 0.8);
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1.4rem;
    font-weight: bolder;
    z-index: 11;
    box-shadow: 0 2px 8px rgba(255, 255, 255, 0.75);
    text-shadow:
            -1px -1px 0 rgba(0, 0, 0, 0.8),
            1px -1px 0 rgba(0, 0, 0, 0.8),
            -1px 1px 0 rgba(0, 0, 0, 0.8),
            1px 1px 0 rgba(0, 0, 0, 0.8);
}



